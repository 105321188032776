import React from "react";
import { Box, Typography, Modal, Backdrop, Fade, Grid } from "@mui/material";

const ModalPerfil = ({ open, handleClose, item }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            outline={'none'}
            disableAutoFocus={true}
            sx={{overflow:'scroll'}}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container>
                        <Grid md={3.5} xs={12}>
                            <img src={item.imgPerfil} width={'100%'} height={'100%'} style={{objectFit:'cover'}}></img>
                        </Grid>
                        <Grid md={1} xs={0}></Grid>
                        <Grid md={7.5} xs={12} textAlign={'left'}>
                            <Typography variant="h4" fontWeight={600} pb={2}>
                                {item.nombre}<br />
                            </Typography>
                            <Typography variant="h6" fontSize={20} pb={4}>
                                {item.carrera}<br />
                            </Typography>
                            <Typography variant="body1" fontSize={14} pb={1}>
                                <strong>Especialista en</strong>
                            </Typography>
                            <Typography variant="h6" fontSize={14} pb={4}>
                                {item.especialidades}<br />
                            </Typography>
                            <Typography textAlign={'justify'}>
                                {item.description}
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY:'scroll',
    maxHeight:'80%'
};

export default ModalPerfil;