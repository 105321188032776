import React, { useCallback } from 'react';
import { styled} from '@mui/material/styles';
import {
  AppBar, Box, Toolbar, IconButton, Typography, Badge,
  MenuItem, Menu, Button, Popover, Table, TableCell, TableBody, TableRow, TableContainer,
  Paper, TableHead, Grid, Card, Input
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircle from '@mui/icons-material/AccountCircle';
import PhoneIcon from '@mui/icons-material/Phone';
import HelpIcon from '@mui/icons-material/QuestionMark';

import 'react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css';

import logo from '../../assets/logo.png';

import './navbar.css';

import { Outlet, useNavigate } from "react-router-dom";

import Footer from '../Footer/Footer';

const PersonalizedButton = styled(Button)({
  padding: '6px 24px',
  margin: '0 10px 0 10px',
  borderRadius: '20px',
  '&:hover': {
    backgroundColor: '#dadada',
    borderColor: '#b3b3b3',
    boxShadow: 'none',
  },
});

export default function PrimarySearchAppBar() {

  const [popNumberState, setPopNumberState] = React.useState(null);

  const handleClickPopNumber = (event) => {
    setPopNumberState(event.currentTarget);
  };

  const handleClosePopNumber = () => {
    setPopNumberState(null);
  };
  const openPopNumber = Boolean(popNumberState);
  const id = openPopNumber ? 'simple-popover' : undefined;


  const [popHelpState, setPopHelpState] = React.useState(null);

  const handleClickPopHelp = (event) => {
    setPopHelpState(event.currentTarget);
  };

  const handleClosePopHelp = () => {
    setPopHelpState(null);
  };
  const openPopHelp = Boolean(popHelpState);
  const idhelp = openPopHelp ? 'simple-popover' : undefined;



  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  function createDataInfo(titlePac, pagePac, descripPac, titleProf, pageProf, descripProf) {
    return { titlePac, pagePac, descripPac, titleProf, pageProf, descripProf };
  }

  const rowsInfo = [
    createDataInfo('Diabetes (who.int)', 'https://www.who.int/es/news-room/fact-sheets/detail/diabetes', 'Sitio web de diabetes de la OMS ',
      'Insulinalilly.com', 'www.Insulinalilly.com', 'Sitio web de Educadores Diabéticos Lily Diabetes '),
    createDataInfo('FMDiabetes.org', 'https://fmdiabetes.org/', 'Sitio Web de la Federación Mexicana de Diabetes',
      'Lilly Conexiones', 'https://lilly-conexiones.com/lacam/landing', 'Educación médica continua'),
    createDataInfo('Lilly Diabetes', 'https://www.diabetes.lilly.es/', 'Sitio web para pacientes y cuidadores de diabetes',
      'LiveMed', 'https://www.livemed.org/', 'Educación médica continua'),
    createDataInfo('Sanofi', 'https://www.sanofi.com.mx/es/solucionesensalud/generalmedicines/diabetesycardiovascular/diabetes', 'Sitio web de información sobre diabetes',
      'MedChannel Updates', 'https://livemed.org/updates/interior.asp?hs=25656fbc924783c2bedfdf6b0d05dc74', 'Educación médica continua'),
    createDataInfo('Diabetes', 'https://salud.edomex.gob.mx/isem/diabetes', 'Sitio web de información sobre diabetes', '', '', ''),
    createDataInfo('Imss', 'http://www.imss.gob.mx/preguntas-de-salud/preguntas-frecuentes-sobre-diabetes', 'Preguntas frecuentes sobre diabetes '),
  ];

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const goPage = (event, route) => {
    event.preventDefault();
    setAnchorEl(null);
    handleMobileMenuClose();
    navigate(route, { replace: true });
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const menuId = 'primary-search-account-menu';
  const renderMenu = (
    <Menu
      anchorReference="anchorPosition"
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      anchorPosition={{ top: 88, left: 0 }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={((e) => goPage(e, 'Diabetes'))}>Diabetes</MenuItem>
      <MenuItem onClick={((e) => goPage(e, 'VidaSaludable'))}>Vida Saludable</MenuItem>
      <MenuItem onClick={((e) => goPage(e, 'Telemedicina'))}>Telemedicina</MenuItem>
      <MenuItem onClick={((e) => goPage(e, 'SobreNosotros'))}>Sobre Nosotros</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <Grid container p={4}>
        <Grid md={12} xs={12}>
          <Typography variant={'body1'}>Usuario</Typography>
          <Input sx={{ mb: 2 }} fullWidth='true'></Input>
          <Typography variant={'body1'}>Contraseña</Typography>
          <Input sx={{ mb: 2 }} fullWidth='true'></Input>
          <div style={{ textAlign: 'center', paddingBottom: '2rem' }}>
            <Typography variant={'caption'}>Olvidaste tu contraseña?</Typography>
          </div>
          <Button variant='outlined' color='secondary' fullWidth={'true'}>Acceder</Button>
        </Grid>
      </Grid>
    </Menu>
  );
  const navigate = useNavigate();
  const handleOnClick = useCallback(() => navigate('/', { replace: true }), [navigate]);
  return (
    <>
      <Box sx={{ flexGrow: 1 }} className={"box"}>
        <AppBar position="static" color='primary' sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} className="appbar">
          <Toolbar>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="medium"
                edge="start"
                aria-label="Menu"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMenuOpen}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
            </Box>
            <div style={{
              padding: 8,
              display: 'flex', justifyContent: 'center',
              alignItems: 'start', flexDirection: 'column'
            }}>
              <img src={logo} height={42} width={150}
                style={{ paddingBottom: 6 }}
                onClick={handleOnClick}
                component="img"
                alt="K2.0 Médica Kolob Logo">
              </img>
              <Typography paddingLeft={0.3} fontSize={12} lineHeight={.8}
                color={'#233351'}>
                Médica Kolob
              </Typography>
              <Typography paddingLeft={0.3} fontSize={12} lineHeight={1.2}
                color={'#233351'}>
                Consulta online de diabetes
              </Typography>
            </div>
            <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1 }}>
              <PersonalizedButton variant='outlined' color='secondary' href='#' onClick={((e) => goPage(e, 'Diabetes'))}>Diabetes</PersonalizedButton>
              <PersonalizedButton variant='outlined' color='secondary' href='#' onClick={((e) => goPage(e, 'VidaSaludable'))}>Vida Saludable</PersonalizedButton>
              <PersonalizedButton variant='outlined' color='secondary' href='#' onClick={((e) => goPage(e, 'Telemedicina'))}>Telemedicina</PersonalizedButton>
              <PersonalizedButton variant='outlined' color='secondary' href='#' onClick={((e) => goPage(e, 'SobreNosotros'))}>Sobre Nosotros</PersonalizedButton>
            </Box>
            <Box sx={{ flexGrow: 80 }} />
            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>
              <IconButton size="large" aria-label="show 4 new mails" color="inherit" on aria-describedby={id} variant="contained" onClick={handleClickPopNumber}>
                <Badge badgeContent={0} color="error">
                  <PhoneIcon />
                </Badge>
              </IconButton>
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={id}
                open={openPopNumber}
                anchorEl={popNumberState}
                onClose={handleClosePopNumber}
                sx={{ textAlign: 'center' }}
              >

                <Typography sx={{ p: 2 }} variant={'h5'}>Médica Kolob</Typography><br></br>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Contacto</strong></TableCell>
                        <TableCell align="right">(+52) 722 176 6606</TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </TableContainer>
                <Card elevation={2} sx={{ textAlign: 'left', padding: 5 }}>
                  <Typography>Para más información, programación o cambio de citas, comunicate al numero de contacto.
                    <br />
                    Para consulta online de diabetes, registrate en nuestro portal.
                    <br />
                    Para atención al usuario, dudas o sugerencias, consulta nuestras redes sociales.
                  </Typography>
                </Card>
              </Popover>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
                on aria-describedby={idhelp}
                variant="contained"
                onClick={handleClickPopHelp}
              >
                <Badge badgeContent={0} color="error">
                  <HelpIcon />
                </Badge>
              </IconButton>
              <Popover
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                id={idhelp}
                open={openPopHelp}
                anchorEl={popHelpState}
                onClose={handleClosePopHelp}
                sx={{ textAlign: 'center' }}
              >
                <Typography sx={{ p: 2 }} variant={'h5'}>Información y Ayuda</Typography>
                <TableContainer component={Paper}>
                  <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Para Pacientes</strong></TableCell>
                        <TableCell><strong>Para Profesionales</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {rowsInfo.map((row) => (
                        <TableRow
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell component="th" scope="row">
                            <a href={row.pagePac} target='_blank'>{row.titlePac}</a><br></br>
                            <Typography variant='subtitle2' fontWeight={500}>{row.descripPac}</Typography>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <a href={row.pageProf} target='_blank'>{row.titleProf}</a><br></br>
                            <Typography variant='subtitle2' fontWeight={500}>{row.descripProf}</Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Popover>
            </Box>
            <Box sx={{ flexGrow: 2 }} />
            <Box sx={{ display: { xs: 'flex', md: 'flex' } }}>

              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
          </Toolbar>
        </AppBar>
        {renderMobileMenu}
        {renderMenu}
      </Box>
      <Outlet className={"outlet"}></Outlet>
      <Footer></Footer>
    </>
  );
}