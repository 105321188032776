
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Link, Breadcrumbs, Typography, Stack, Button, Box, Paper, MobileStepper } from "@mui/material";
import { Container, palette } from "@mui/system";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import styles from './page3.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import { House, HealthAndSafety, Devices, VideoCall, Schedule, SystemUpdate } from '@mui/icons-material';




const Telemedicina = () => {
    const theme = useTheme();
    AOS.init();
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/', { replace: true }), [navigate]);

    const [activeStep, setActiveStep] = React.useState(0);
    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };


    return (
        <div>
            <div className={styles.div}>
                <Grid container spacing={0}>
                    <Grid md={6} xs={0}></Grid>
                    <Grid md={6} xs={12}>
                        <h1>Portal del paciente<br /></h1>
                    </Grid>
                </Grid>
            </div>

            <Container sx={{ padding: '3rem 2.5rem 3rem 2.5rem' }}>
                <Grid container spacing={1} rowGap={6} >
                    <Grid xs={12} md={12}>
                        <Typography variant='h6' textAlign={'center'}>
                            Bienvenido al portal del paciente de Medica Kolob.<br/>
                            Una plataforma digital para facilitar la comunicación entre  pacientes, cuidadores, familiares y el equipo multidisciplinario.<br/>
                            Contamos con
                        </Typography>
                    </Grid>
                    <Grid xs={6} md={4}>
                        <Stack direction={'column'} alignItems={'center'} textAlign={'center'}>
                            <HealthAndSafety style={{ fontSize: '10rem', color: '#3F3F3F' }}></HealthAndSafety>
                            <Typography variant='h6'>Atención Personalizada</Typography>
                            <Typography variant='subtitle2'>Comunicate e intercambia información con tu equipo asistencial.</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={6} md={4}>
                        <Stack direction={'column'} alignItems={'center'} textAlign={'center'}>
                            <House style={{ fontSize: '10rem', color: '#3F3F3F' }}></House>
                            <Typography variant='h6'>Sin Desaplazamientos</Typography>
                            <Typography variant='subtitle2'>Ahorra tiempo, evita filas y viajes al hospital.</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={4} pb={20}>
                        <Stack direction={'column'} alignItems={'center'} textAlign={'center'}>
                            <Devices style={{ fontSize: '10rem', color: '#3F3F3F' }}></Devices>
                            <Typography variant='h6'>Donde Quieras</Typography>
                            <Typography variant='subtitle2'>Accede desde tu ordenador, tablet o smartphone.</Typography>
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <div className={styles.divCentered}>
                            <Typography variant={'h4'}>Servicios que ofrece nuestro Portal</Typography>
                        </div>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <Box sx={{ maxWidth: '100%', flexGrow: 1 }}>
                            <Paper
                                square
                                elevation={0}
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: 400,
                                    bgcolor: '#cdcdcd',
                                    justifyContent: 'center',
                                }}
                            >
                                <div className={styles.stepper} style={{
                                    backgroundImage:
                                        `linear-gradient(45deg, rgba(34, 193, 195, .8) 0%, rgba(89, 139, 159, 0.82) 26%, rgba(89, 139, 159, 0.85) 69%, rgba(45, 149, 253, .8) 100%),
                                    url(${steps[activeStep].imgPath})`
                                }}>
                                    {steps[activeStep].icon}
                                    <Typography variant='h6'>{steps[activeStep].label}</Typography>
                                    <Typography variant='subtitle2'>{steps[activeStep].description}</Typography>
                                </div>
                            </Paper>

                            <MobileStepper
                                steps={maxSteps}
                                position="static"
                                activeStep={activeStep}
                                nextButton={
                                    <Button
                                        size="small"
                                        onClick={handleNext}
                                        disabled={activeStep === maxSteps - 1}
                                        color={'secondary'}
                                    >
                                        Next
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowLeft />
                                        ) : (
                                            <KeyboardArrowRight />
                                        )}
                                    </Button>
                                }
                                backButton={
                                    <Button size="small" onClick={handleBack} disabled={activeStep === 0}
                                        color={'secondary'}>
                                        {theme.direction === 'rtl' ? (
                                            <KeyboardArrowRight />
                                        ) : (
                                            <KeyboardArrowLeft />
                                        )}
                                        Back
                                    </Button>
                                }
                            />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}
const steps = [
    {
        icon: <VideoCall style={{ fontSize: '10rem' }}></VideoCall>,
        label: 'Videoconferencia',
        description: 'Contacta de forma segura y al instante con tu equipo asistencial.',
        imgPath:
            'https://i0.wp.com/gammaknife.com.ec/wp-content/uploads/2020/05/telemedicina.jpg?fit=980%2C528&ssl=1',
    },
    {
        icon: <House style={{ fontSize: '10rem' }}></House>,
        label: 'Agenda',
        description: 'Consulta tus citas.',
        imgPath:
            'https://nanova.org/wp-content/uploads/2020/05/y4r821sw.jpg',
    },
    {
        icon: <Devices style={{ fontSize: '10rem' }}></Devices>,
        label: 'Información sobre tu condición de salud',
        description: 'Comparte información sobre tu estado de salud así como estudios laboratorio y/o gabinete  con tu equipo multidisciplinario.',
        imgPath:
            'https://thestandardcio.com/wp-content/uploads/sites/2/2020/06/medical-tech.jpg',
    },
];

export default Telemedicina;