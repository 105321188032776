import React, { useEffect } from "react";
import { Box, Container, Grid, Paper, Typography } from "@mui/material";
import './footer.css';
import map from "../../assets/map.png";
import 'aos/dist/aos.css';
import { Facebook, Instagram, Twitter, YouTube, WhatsApp } from "@mui/icons-material";

const Footer = () => {
    return (
        <Grid container bgcolor={"#EBEBEB"} className="footercontainer">
            <Grid md={6} xs={12}>
                <h1 style={{
                    color: "#3F3F3F",
                    textAlign: "left",
                }}>
                    Contáctanos
                </h1>
                <h3 style={{
                    color: "#3F3F3F",
                    textAlign: "left",
                }}>
                    Ubicación<br />
                </h3>
                <Typography variant='subtitle2'>
                    Médica Kolob<br /><br />
                    Calle Emiliano Zapata, S/N.<br />
                    Esquina con calle 7 Oriente.<br />
                    Frente a la escuela primaria.<br />
                    Xonacatlán, Estado de México, México.
                </Typography>
                <h3 style={{
                    color: "#3F3F3F",
                    textAlign: "left",
                }}>
                    Horarios<br />
                </h3>
                <Typography variant='subtitle2'>
                    Lunes a Viernes de 18:00 a 22:00 hrs<br />
                </Typography>
                <h3 style={{
                    color: "#3F3F3F",
                    textAlign: "left",
                }}>
                    Teléfono<br />
                </h3>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <WhatsApp />
                    <Typography variant='subtitle2' paddingTop={.1}>
                        +(52) 722 176 6606
                    </Typography>
                </div>
                <h3 style={{
                    color: "#3F3F3F",
                    textAlign: "left",
                }}>
                    Redes Sociales<br />
                </h3>
                <Typography variant='subtitle2'>
                    Siguenos en<br />
                    <Facebook></Facebook>
                    <Twitter></Twitter>
                    <YouTube></YouTube>
                    <Instagram></Instagram>
                </Typography>
            </Grid>
            <Grid md={6} xs={12}>
                <div
                    style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Paper elevation={1} sx={{ padding: 1, borderRadius: '0', backgroundColor: 'whitesmoke' }}>
                        <a href="https://goo.gl/maps/adpnckJ56j7CMq9c8" target="#">
                            <img src={map} height={500} width={'100%'} style={{ borderRadius: 0, objectFit: 'cover' }} />
                        </a>
                    </Paper>
                </div>
            </Grid>
        </Grid>
    );
};
export default Footer;
