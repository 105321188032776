import Home from './Pages/Main/Body';
import Page1 from './Pages/Page1/Page1';
import Page2 from './Pages/Page2/Page2';
import Page3 from './Pages/Page3/Page3';
import Page4 from './Pages/Page4/Page4';
import Navbar from './Pages/Header/Navbar';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@emotion/react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';


function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: '#EBEBEB',
      },
      secondary: {
        main: '#3F3F3F',
      },
    },
  });

  return (

    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Navbar />}>
            <Route index path="" element={<Home/>} />
            <Route path="Diabetes" element={<Page1 />} />
            <Route path="VidaSaludable" element={<Page2 />} />
            <Route path="Telemedicina" element={<Page3 />} />
            <Route path="SobreNosotros" element={<Page4 />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
