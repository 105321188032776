import { Divider, Grid, Link, Breadcrumbs, Typography, Stack, Button, Box } from "@mui/material";
import { NavigateNext } from '@mui/icons-material';
import { Container } from "@mui/system";
import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import img from '../../assets/banner1.png';
import img2 from '../../assets/banner2.jpg';
import styles from './page1.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';


const Diabetes = () => {
    AOS.init();
    const navigate = useNavigate();
    const handleOnClick = useCallback(() => navigate('/', { replace: true }), [navigate]);
    const breadcrumbs = [
        <Link underline="hover" key="1" color="inherit" href="#" onClick={handleOnClick}>
            Inicio
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="inherit"
            onClick={
                navigate('', { replace: true })}
        >
            Diabetes
        </Link >,
        // <Typography key="3" color="text.primary">
        //     Breadcrumb
        // </Typography>,
    ];
    return (
        <div>
            <div className={styles.div}>
                <Grid container spacing={0}>
                    <Grid md={6} xs={0}></Grid>
                    <Grid md={6} xs={12}>
                        <h1>¿Qué es la <u>diabetes</u>?</h1>
                        <h2>Informate y no vivas con temor.</h2><br></br>
                    </Grid>
                </Grid>
            </div>

        <Container sx={{ padding: '3rem 2.5rem 3rem 2.5rem'}}>
            <Grid container spacing={1} rowGap={6} >
                <Grid xs={12} md={12}>
                    <Typography variant='h6' textAlign={'justify'}>
                        La diabetes es una enfermedad crónica que aparece cuando el páncreas no produce insulina suficiente o cuando el organismo no utiliza eficazmente la insulina que produce. La insulina es una hormona que regula el azúcar en la sangre. El efecto de la diabetes no controlada es la hiperglucemia (aumento del azúcar en la sangre), que con el tiempo daña gravemente muchos órganos y sistemas, especialmente los nervios y los vasos sanguíneos.
                    </Typography>
                </Grid>
                <Grid xs={12} md={5.5}>
                    <Typography variant='h3' paddingBottom={4}>
                        Diabetes tipo 1
                    </Typography>
                    <Typography variant="subtitle1" textAlign="justify">
                        La diabetes de tipo 1 (también llamada insulinodependiente, juvenil o de inicio en la infancia) se caracteriza por una producción deficiente de insulina y requiere la administración diaria de esta hormona. Se desconoce aún la causa de la diabetes de tipo 1 y no se puede prevenir con el conocimiento actual.<br />
                        Sus síntomas consisten, entre otros, en excreción excesiva de orina (poliuria), sed (polidipsia), hambre constante (polifagia), pérdida de peso, trastornos visuales y cansancio. Estos síntomas pueden aparecer de forma súbita.
                    </Typography>
                </Grid>
                <Grid xs={0} md={1} />
                <Grid xs={12} md={5.5}>
                    <Typography variant='h3' paddingBottom={4}>
                        Diabetes tipo 2
                    </Typography>
                    <Typography variant="subtitle1" textAlign="justify">
                        La diabetes de tipo 2 (también llamada no insulinodependiente o de inicio en la edad adulta) se debe a una utilización ineficaz de la insulina. Este tipo representa la mayoría de los casos mundiales y se debe en gran medida a un peso corporal excesivo y a la inactividad física.<br />
                        Los síntomas pueden ser similares a los de la diabetes de tipo 1, pero a menudo menos intensos. En consecuencia, la enfermedad puede diagnosticarse solo cuando ya tiene varios años de evolución y han aparecido complicaciones.<br />
                        Hasta hace poco, este tipo de diabetes solo se observaba en adultos, pero en la actualidad también se está manifestando en niños.
                    </Typography>
                </Grid>
                <Grid xs={12} md={5.5}>
                    <div data-aos='fade-right' className={styles.divImg}>
                        <img src={img} alt='not found' className={styles.img}></img>
                    </div>
                </Grid>
                <Grid md={1} xs={0}></Grid>
                <Grid xs={12} md={5.5}>
                    <Typography variant='h3' paddingBottom={3}>
                        Datos y Cifras
                    </Typography>
                    <Typography variant='subtitle1' paddingLeft={.4}>
                        <ul style={{ textAlign: 'justify' }}>
                            <li>
                                La diabetes es la 4ª causa de muerte por ENT en las Américas.
                            </li>
                            <li>
                                La prevalencia de la diabetes* en adultos (mayores de 18 años) en las Américas fue de 8.3% en 2016.
                            </li>
                            <li>
                                La diabetes, si no controlada, es una importante causa de ceguera, insuficiencia renal, infarto de miocardio, accidente cerebrovascular y amputación de los miembros inferiores.
                            </li>
                            <li>
                                En 2016, 342.603 personas murieron debido a la diabetes en la Región.
                            </li>
                            <li>
                                La tasa de mortalidad por diabetes en las Américas fue de 33.1 por 100.000 habitantes en 2016, y fue más alta en países del Caribe No-Latino.
                            </li>
                            <li>
                                La diabetes se puede prevenir mediante la adopción de estilos de vida saludables y manteniendo un peso corporal normal, y en personas con diabetes, se puede controlar de manera eficaz con medicación y estilos de vida saludables
                            </li>
                        </ul>
                        * Definición de la diabetes utilizada en las estimaciones: paciente con glucemia en ayunas ≥ 7,0 mmol/l o medicado, o con antecedentes de diagnóstico de diabetes.
                    </Typography>
                </Grid>
                <Grid xs={12} md={5.5}>
                    <Typography variant='h3' paddingBottom={4}>
                        ¿Cuales son las consecuencias frecuentes de la diabetes?
                    </Typography>
                    <Typography variant="subtitle1" textAlign="justify">
                        Con el tiempo, la diabetes puede dañar el corazón, los vasos sanguíneos, ojos, riñones y nervios.<br />
                        Los adultos con diabetes tienen un riesgo 2 a 3 veces mayor de infarto de miocardio y accidente cerebrovascular.<br />
                        La neuropatía de los pies combinada con la reducción del flujo sanguíneo incrementan el riesgo de úlceras de los pies, infección y, en última instancia, amputación.<br />
                        La retinopatía diabética es una causa importante de ceguera y es la consecuencia del daño de los pequeños vasos sanguíneos de la retina que se va acumulando a lo largo del tiempo. El 2,6% de los casos mundiales de ceguera es consecuencia de la diabetes.<br />
                        La diabetes se encuentra entre las principales causas de insuficiencia renal.<br />

                    </Typography>
                </Grid>
                <Grid md={1} xs={0}></Grid>
                <Grid xs={12} md={5.5}>
                    <div data-aos='fade-right' className={styles.divImg}>
                        <img src={img2} alt='not found' className={styles.img}></img>
                    </div>
                </Grid>
                <Grid xs={12} md={12}>
                    <Typography variant='h3' paddingBottom={4}>
                        Prevención
                    </Typography>
                    <Typography variant="subtitle1" textAlign="justify">
                        Se ha demostrado que medidas simples relacionadas con el estilo de vida son eficaces para prevenir la diabetes de tipo 2 o retrasar su aparición.<br />Para ayudar a prevenir la diabetes de tipo 2 y sus complicaciones se debe:<br />
                        <ul>
                            <li>
                                Alcanzar y mantener un peso corporal saludable.
                            </li>
                            <li>
                                Mantenerse activo físicamente: al menos 30 minutos de actividad regular de intensidad moderada la mayoría de los días de la semana; para controlar el peso puede ser necesaria una actividad más intensa.
                            </li>
                            <li>
                                Consumir una dieta saludable, que evite el azúcar y las grasas saturadas.
                            </li>
                            <li>
                                Evitar el consumo de tabaco, puesto que aumenta el riesgo de sufrir diabetes y enfermedades cardiovasculares.
                            </li>
                        </ul>
                    </Typography>
                </Grid>
                <Grid xs={12} md={12}>
                    <div className={styles.divImgBanner} data-aos='flip-up'>
                        <img className={styles.img} src="https://post.healthline.com/wp-content/uploads/2016/05/AN32-Heart-Plate-Jumprope-732x549-thumb.jpg">
                        </img>
                    </div>
                    <Typography variant='h3' paddingBottom={4}>
                        Diagnóstico y Tratamiento
                    </Typography>
                    <Typography variant="subtitle1" textAlign="justify">
                        El diagnóstico se puede establecer tempranamente con análisis de sangre relativamente baratos.<br />
                        El tratamiento de la diabetes consiste en una dieta saludable y actividad física, junto con la reducción de la glucemia y de otros factores de riesgo conocidos que dañan los vasos sanguíneos. Para evitar las complicaciones también es importante dejar de fumar.<br />
                        Entre las intervenciones que son factibles y económicas en los países en desarrollo se encuentran:<br />


                        el control de la tensión arterial; y


                        Otras intervenciones económicas son:

                        las pruebas de detección de retinopatía (causa de ceguera).
                        el control de los lípidos de la sangre (regulación de la concentración de colesterol).
                        la detección de los signos tempranos de nefropatía relacionada con la diabetes.

                        <ul>
                            <li>
                                El control de la glucemia, en particular en las personas que padecen diabetes de tipo 1.<br />Los pacientes con diabetes de tipo 1 necesitan insulina y los pacientes con diabetes de tipo 2 pueden tratarse con medicamentos orales, aunque también pueden necesitar insulina;
                            </li>
                            <li>
                                El control de la tensión arterial
                            </li>
                            <li>
                                Los cuidados podológicos
                            </li>
                            <li>
                                Evitar el consumo de tabaco, puesto que aumenta el riesgo de sufrir diabetes y enfermedades cardiovasculares.
                            </li>
                        </ul>
                    </Typography>
                </Grid>
            </Grid>
        </Container>
        </div>
    );
}

export default Diabetes;