import React from "react";
import { Box, Typography, Modal, Backdrop, Fade, Grid } from "@mui/material";

const ModalComida = ({ open, handleClose, item }) => {
    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
            outline={'none'}
            disableAutoFocus={true}
            sx={{ overflow: 'scroll' }}
        >
            <Fade in={open}>
                <Box sx={style}>
                    <Grid container>
                        <Grid md={6} xs={12}>
                            <img src={item.img} width='100%' height={'100%'} style={{ objectFit: 'cover' }}></img>
                        </Grid>
                        <Grid md={.5} xs={0}></Grid>
                        <Grid md={5.5} xs={12}>
                            <Typography variant="h4" fontWeight={600} pb={4}>
                                {item.title}
                            </Typography>
                            <Typography variant={'body1'} pb={2}>
                                <strong>Ingredientes</strong>
                            </Typography>
                            <Typography variant="subtitle2">
                                {item.ingredients}<br />
                            </Typography>
                        </Grid>
                        <Grid md={12} xs={12} pt={6} textAlign={'justify'}>
                            <Typography variant={'body1'} pb={2}>
                                <strong>Preparación</strong>
                            </Typography>
                            <Typography>
                                {item.description}
                            </Typography></Grid>
                    </Grid>
                </Box>
            </Fade>
        </Modal>
    );
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    overflowY: 'scroll',
    maxHeight: '85%'
};

export default ModalComida;