import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import styles from './page2.module.css';

import { Typography, Box, Grid, ImageList, ImageListItem, ImageListItemBar } from "@mui/material";
import { Container } from "@mui/system";
import ModalComida from './Modal';

function srcset(image, width, height, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${width * cols}&h=${height * rows
            }&fit=crop&auto=format&dpr=2 2x`,
    };
}


const Page2 = () => {
    AOS.init();

    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState([]);
    const handleOpen = (e, item) => {
        setItem(item);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return (
        <div>
            <div className={styles.div}>
                <Grid container spacing={0}>
                    <Grid md={6} xs={0}></Grid>
                    <Grid md={6} xs={12}>
                        <h1>Vida Saludable.</h1>
                        <h2>Cocina con nosotros.</h2><br />
                    </Grid>
                </Grid>
            </div>
            <Container sx={{ paddingTop: 5, paddingBottom: 5 }} id='info'>
                <Grid container>
                    <Grid md={12} xs={12} className='topic'>
                        <Box sx={{ paddingRight: { md: 10 }, paddingBottom: { xs: 4 } }} >
                            <Typography variant={'h3'} sx={{ paddingBottom: 5 }}>
                                <strong>Recetas</strong>
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                                Como parte de nuestros servicios, contamos con un amplio recetario que
                                sirve como apoyo para que usted realice estas comidas y cuide de su cuerpo.<br />
                                Aprende como dando click sobre la receta de su preferencia.
                            </Typography>
                        </Box>
                        <ImageList
                            sx={{
                                // Promote the list into its own layer in Chrome. This costs memory, but helps keeping high FPS.
                                transform: 'translateZ(0)',
                                overflow: 'hidden'
                            }}
                            gap={1}
                        >
                            {itemData.map((item) => {
                                const cols = item.featured ? 2 : 1;
                                const rows = item.featured ? 2 : 1;

                                return (
                                    <ImageListItem key={item.img} cols={cols} rows={rows} onClick={((e) => handleOpen(e, item))}>
                                        <img
                                            {...srcset(item.img, 250, 200, rows, cols)}
                                            alt={item.title}
                                            loading="lazy"
                                        />
                                        <ImageListItemBar
                                            sx={{
                                                background:
                                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                            }}
                                            title={item.title}
                                            position="top"
                                        />
                                    </ImageListItem>
                                );
                            })}
                        </ImageList>
                    </Grid>
                </Grid>
            </Container>
            <div>
                <ModalComida open={open} handleClose={handleClose} item={item} />
            </div>
        </div>
    );
}
const itemData = [
    {
        img: 'https://ep01.epimg.net/elcomidista/imagenes/2022/03/29/receta/1648541175_113670_1648541826_media_normal.jpg',
        title: 'Salteado de pollo',
        featured: true,
        ingredients: <>Para 2 personas<br />
            Para el pollo<br />
            <ul>
                <li>300 g de pechuga de pollo</li>
                <li>Una cucharadita de curry en polvo</li>
                <li>Media cucharadita de comino en polvo</li>
                <li>Una cucharada de aceite de oliva</li>
                <li>2 cucharadas de almidón de maíz o fécula de patata</li>
                <li>Sal al gusto</li>
            </ul>
            Para el salteado<br />
            <ul>
                <li>El pollo marinado</li>
                <li>80 g de pimiento rojo</li>
                <li>100 g de brócoli (flores por una parte y tronco por otra)</li>
                <li>Una cebolla morada pequeña</li>
                <li>4 dientes de ajo</li>
                <li>Una cucharadita de jengibre fresco</li>
                <li>Una cucharadita de pimentón dulce</li>
                <li>Una cucharadita de comino molido</li>
                <li>Una cucharadita de curry en polvo</li>
                <li>1,5 cucharadas de mantequilla</li>
                <li>½ cucharada de aceite de oliva</li>
                <li>Sal al gusto</li>
            </ul>
        </>,
        description: <>
            <ol>
                <li>Cortar la pechuga en dados de unos 1,5 o dos centímetros de grosor y marinarla con el curry, el comino, el almidón de maíz o fécula de patata, el aceite de oliva y la sal mientras cortamos las verduras.</li>
                <li>Cortar el pimiento rojo, la cebolla y el brócoli de forma que tengan más o menos el mismo tamaño.</li>
                <li>Majar los dientes de ajo con el jengibre, el pimentón, el comino, el curry y un poco de sal hasta que formen una pasta.</li>
                <li>Calentar la mantequilla en una sartén a fuego alto y añadir el pollo. Repartir bien y dejar que se dore sin remover. Cuando esté bien marcado por una sola cara, remover 10 segundos y retiramos de la sartén.</li>
                <li>Añadir el aceite junto a la mantequilla que queda en la sartén y saltear la pasta de ajos a fuego medio hasta que huela.</li>
                <li>Subir el fuego a alto y añadir el pollo y las verduras. Remover para que se impregne todo de la pasta de ajos. Repartir por toda la sartén y cocinar sin mover 15 segundos. Remover otros 10 segundos y servir.</li>
            </ol>
        </>
    },
    {
        img: 'https://40aprons.com/wp-content/uploads/2021/10/keto-white-chocolate-raspberry-cheesecake-3.jpg',
        title: 'Cheesecake de frambuesa y chocolate blanco keto (sin azúcar)',
        ingredients: <>
            Tiempo de preparación 15 minutos<br />
            Tiempo de cocción 1 hora 30 minutos<br />
            Tiempo de enfriamiento 8 hrs<br />
            Para la corteza de tarta de queso cetogénica
            <ul>
                <li>2 tazas de harina de almendras</li>
                <li>⅓ taza de mantequilla medida, luego derretida (pero no caliente)</li>
                <li>3 cucharadas de Swerve granular</li>
                <li>1 pizca de sal</li>
                <li>1 pizca de canela</li>
                <li>1 cucharadita de extracto de vainilla</li>
            </ul>
            Para la salsa keto de frambuesa<br />
            <ul>
                <li>1 paquete de 10 onzas de frambuesas congeladas</li>
                <li>2 cucharadas de Swerve granulado</li>
                <li>¼ de cucharadita de goma xantana</li>
                <li>½ taza de agua a temperatura ambiente</li>
            </ul>
            Para la tarta de queso cetogénica<br />
            <ul>
                <li>9 onzas de chispas de chocolate blanco ceto como Lily’s o ChocZero</li>
                <li>3 paquetes de 8 onzas de queso crema a temperatura ambiente, se prefiere Philadelphia Original</li>
                <li>¾ de taza de Confectioners ‘Swerve más ¼-½ más si lo desea</li>
                <li>3 huevos grandes a temperatura ambiente</li>
                <li>1 cucharadita de extracto de vainilla</li>
            </ul>

        </>,
        description: <>
            Para la corteza de tarta de queso cetogénica
            <ol>
                <li>En un tazón mediano, mezcle la harina de almendras, la mantequilla derretida, el Swerve granulado, la sal, la canela y el extracto de vainilla hasta que la masa esté bien combinada y ligeramente desmenuzada. Presione la mezcla de masa en el fondo del molde desmontable, formando una capa uniforme.</li>
                <li>Coloque la sartén en el horno precalentado y hornee aproximadamente 8 minutos, hasta que la corteza comience a dorarse, teniendo mucho cuidado de no hornear demasiado. Retire la sartén del horno y deje que la corteza se enfríe por lo menos 10 minutos.</li>
            </ol>
            Para la salsa keto de frambuesa<br />
            <ol>
                <li>En una cacerola mediana, mezcle las frambuesas congeladas, el Swerve granular, la goma xantana y el agua.</li>
                <li>Deje hervir la mezcla y cocine 5 minutos o hasta que la salsa esté espesa.</li>
                <li>Retire la cacerola del fuego. Coloque un colador de malla fina sobre un tazón mediano vacío y vierta la salsa a través del colador para quitar las semillas. Deje enfriar la salsa de frambuesa colada.</li>
            </ol>
            Para la tarta de queso cetogénica<br />
            <ol>

                <li>En un tazón mediano apto para microondas, calienta las chispas de chocolate blanco en el microondas al 50% de potencia durante 30 segundos. Revuelva las chispas de chocolate. Continúe calentando el chocolate en el microondas al 50% de potencia en ráfagas de 20 segundos, revolviendo después de cada ráfaga, hasta que el chocolate se derrita. Tenga mucho, mucho cuidado de no cocinar demasiado el chocolate.</li>
                <li>En un tazón grande, mezcle el queso crema ablandado y el postre de repostería con una batidora hasta que la mezcla esté suave.</li>
                <li>Uno a la vez, rompa y bata cada huevo en la mezcla de queso crema, incorporando completamente un huevo antes de agregar otro.</li>
                <li>Mezcle lentamente el extracto de vainilla y las chispas de chocolate blanco derretidas hasta que estén completamente combinadas.</li>
                <li>Una vez combinados, vierta la mitad de la mezcla de pastel de queso con chocolate blanco en un molde desmontable sobre la corteza enfriada. Vierta 3 cucharadas de salsa de frambuesa sobre la primera capa de la mezcla de tarta de queso.</li>
                <li>Vierta la mezcla restante de tarta de queso en un molde desmontable. Vierta 3 cucharadas más de salsa de frambuesa sobre la parte superior del pastel de queso. Si lo desea, arrastre la punta del cuchillo a través de la parte superior de la tarta de queso para crear un efecto de mármol u otros diseños.</li>
                <li>Coloque el molde desmontable en el horno sobre la rejilla del medio. Hornee a 325 ° Fahrenheit durante 45 a 55 minutos. Retire el pastel de queso del horno cuando el centro esté casi listo, pero aún se mueva. Tenga cuidado de no cocinar demasiado.</li>
                <li>Deje enfriar el pastel de queso a temperatura ambiente para evitar que se agriete. Una vez que esté completamente frío, cubra el molde desmontable con papel film y colóquelo en el refrigerador durante al menos 8 horas. Después de 8 horas, corte en rodajas y sirva frío con el resto de la salsa de frambuesa.</li>
            </ol>
        </>
    },
    {
        img: 'https://buenprovecho.hn/wp-content/uploads/2020/10/Pasta_Alfredo2.jpeg.jpg',
        title: 'Pasta Alfredo con pollo',
        ingredients: <>
            Rinde 4 porciones<br />
            Preparación 15 min.<br />
            Tiempo Total 25 min.<br />
            <ul>
                <li>2 pechugas de pollo deshuesadas y sin piel</li>
                <li>Sal y pimienta negra recién molida</li>
                <li>2 cucharadas de aceite vegetal</li>
                <li>8 cucharadas (1 barra) de mantequilla amarilla</li>
                <li>2 dientes de ajo picados</li>
                <li>1 taza de crema de leche</li>
                <li>1 taza de queso parmesano rallado, más un poco para servir</li>
                <li>¼ cucharadita de nuez moscada en polvo</li>
                <li>1 paquete de pasta de fettuccine cocido al dente</li>
                <li>Cantidad necesaria de hojas de perejil picado</li>
            </ul>

        </>,
        description: <>
            <ol>
                <li>Secar las pechugas de pollo con toallas de papel. Sazonar con sal y pimienta.</li>
                <li>Calentar el aceite a fuego medio alto en una sartén grande. Agregar el pollo a la sartén y cocinar durante 5 a 7 minutos. Si intentas darle vuelta al pollo y si no se mueve, no está listo para voltear.</li>
                <li>Agregar 1 cucharada de mantequilla justo entre ellos. Levantar la sartén y agitarla suavemente para distribuir la mantequilla derretida. Cocinar el pollo por 10 minutos más.</li>
                <li>Transferir el pollo a un plato o tabla de cortar limpia y dejar reposar durante 3 minutos. Cortar en rodajas, cubrir con papel de aluminio mientras preparas la pasta.</li>
                <li>Agregar a la sartén las 7 cucharadas restantes de mantequilla y llevar a fuego medio hasta que se derrita. Agregar el ajo y saltear de 30 segundos a 1 minuto.</li>
                <li>Para la salsa Alfredo, batir la crema, el parmesano, la nuez moscada, el ¼ cucharadita restante de sal y el ¼ cucharadita de pimienta restante. Cocinar en una cacerola a fuego lento durante 3 a 4 minutos. Si está demasiado espesa, agregar un poco del agua reservada de la pasta, para diluirla.</li>
                <li>Agregar el fettuccine a la salsa y revolver para cubrir. Servir la pasta con unas rodajas de pollo. Rociar con perejil picado, más parmesano y pimienta negra si lo desea.</li>
            </ol>
        </>
    },
    {
        img: 'https://assets.unileversolutions.com/recipes-v2/109069.jpg',
        title: 'Chiles rellenos de queso',
        featured: true,
        ingredients: <>
            Raciones 5<br />
            Calorías 150kcal<br />
            Tiempo de preparación 20 minutos<br />
            Tiempo de cocción 30 minutos<br />
            Tiempo total 50 minutos<br />

            <ul>
                <li>5 chiles poblanos</li>
            </ul>
            Para el relleno
            <ul>
                <li>250 gramos de queso panela</li>
            </ul>
            Para la salsa o caldillo
            <ul>
                <li>3 tomates rojos o jitomates</li>
                <li>2 tazas de caldo de pollo</li>
                <li>2 dientes de ajo</li>
                <li>1/2 cebolla blanca pequeña o 1/3 de cebolla grande</li>
                <li>2 ramas de perejil fresco</li>
                <li>Un toque de orégano opcional</li>
                <li>Sal</li>
                <li>Pimienta negra molida</li>
            </ul>
        </>,
        description: <>
            <ol>
                <li>Comenzaremos por precalentar el horno a 180 grados.</li>
                <li>Luego, vamos a asar los tomates rojos o jitomates sobre un comal o plancha. En su defecto, podemos utilizar una sartén. La idea es irlos volteando para asarlos por todas sus caras.</li>
                <li>También pelaremos los dientes de ajo y los asaremos, igual que el trozo de cebolla.</li>
                <li>Luego, asaremos los chiles poblanos. Iremos rotándolos sobre el comal o plancha, o incluso directamente sobre el fuego. Posiblemente, la piel se ampollará o se ennegrecerá en algunas partes. Esto es normal.</li>
                <li>Dejaremos que los chiles se reposen unos cinco minutos, encerrados en una bolsa de plástico, para que suden. Luego, retiraremos cuidadosamente la piel. El proceso que hemos realizado previamente hará que este paso sea más fácil. Si hace falta, puedes ayudarte pasándolos bajo un chorro de agua para retirar los últimos trocitos de piel que queden.</li>
                <li>Para continuar, con un cuchillo bien afilado, haremos un corte lateral de la cabeza al extremo inferior de cada chile.</li>
                <li>Muy cuidadosamente, nos ayudaremos con el cuchillo para retirar las semillas del interior de cada chile, y la vena o parte blanca. Haremos esto a través del corte vertical que hemos realizado en el paso anterior.</li>
                <li>Corta el queso panela en barras y distribúyelo en partes iguales para rellenar todos los chiles. Introduce las barras de queso en cada chile, a través del corte vertical.</li>
                <li>Meteremos los chiles al horno en un refractario o recipiente similar, durante 20 minutos.</li>
                <li>Mientras tanto, aprovecharemos para preparar el caldillo o salsa. En una licuadora, procesaremos los jitomates asados con o sin piel (yo prefiero dejarles la piel), el ajo, la cebolla, las hojas de perejil (bien lavadas), el caldo de pollo y un toque de sal, pimienta y orégano.</li>
                <li>Una vez bien líquido el caldillo, lo pasaremos a una olla y lo mantendremos a fuego bajo. Es recomendable probarlo para verificar y corregir la sazón, si es necesario.</li>
                <li>Cuando saquemos los chiles del horno, los serviremos en un plato, bañados con un poco de caldillo. Si hemos utilizado palillos de madera para cerrarlos, hay que retirarlos antes de servir.</li>
            </ol>
        </>
    },
];
export default Page2;