
import { useTheme } from '@mui/material/styles';
import { Divider, Grid, Link, Breadcrumbs, Typography, Stack, Button, Box, Paper, MobileStepper, Card } from "@mui/material";
import { Container, palette } from "@mui/system";
import React, { useCallback } from "react";
import styles from './page4.module.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

import img1 from '../../assets/imgPerfil/RBC.jpg';
import img2 from '../../assets/imgPerfil/SNGR.jpeg';
import img3 from '../../assets/imgPerfil/AJR.jpg';
import img4 from '../../assets/imgPerfil/JJGC.jpg';
import img5 from '../../assets/imgPerfil/EGA.jpg';
import img6 from '../../assets/imgPerfil/KASR.png';
import img7 from '../../assets/imgPerfil/SPT.jpg';
import img8 from '../../assets/imgPerfil/AMFC.jpg';
import ModalPerfil from './Modal';


const SobreNosotros = () => {
    AOS.init();

    const [open, setOpen] = React.useState(false);
    const [item, setItem] = React.useState([]);
    const handleOpen = (e, item) => {
        setItem(item);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    return (
        <div>
            <div className={styles.divImg}>
                <div className={styles.div}>
                    <Grid container spacing={0}>
                        <Grid md={6} xs={0}></Grid>
                        <Grid md={6} xs={12}>
                            <h1>Conocenos<br /></h1>
                            <h2>Nuestro equipo de trabajo esta siempre para ti.</h2>
                        </Grid>
                    </Grid>
                </div>
            </div>

            <Container sx={{ padding: '3rem 2.5rem 3rem 2.5rem' }}>
                <Grid container spacing={1} rowGap={6} >
                    <Grid xs={12} md={12}>
                        <Typography variant='h3' textAlign={'justify'} fontWeight="600" color={"#42537d"}>
                            Equipo Multidisciplinario
                        </Typography>
                    </Grid>
                    {medicos.map((item, index) => {
                        return (
                            <Grid xs={12} md={4} p={2}>
                                <Card sx={{ borderRadius: 4 }} elevation={5} onClick={((e)=>handleOpen(e,item))} style={{height:'560px'}}>
                                    <div className={styles.cardProfile}>
                                        <img src={item.imgPerfil} className={styles.imgProfile}></img>
                                        <Typography variant={'h6'} sx={{ width: '100%' }}><strong>{item.nombre}</strong></Typography>
                                        <Typography variant={'subtitle1'} sx={{ width: '100%' }}>{item.carrera}</Typography>
                                    </div>
                                </Card>
                            </Grid>
                        );
                    })}
                    <Grid xs={12} md={12}>

                    </Grid>
                </Grid>
            </Container>

            <div>
                <ModalPerfil open={open} handleClose={handleClose} item={item} />
            </div>
        </div>
    );
}

const medicos = [
    {
        nombre: 'Rosalba Butrón Cañada',
        carrera: <>Médico Cirujano<br />Miembro activo de ANMGyF</>,
        especialidades: <>Doctora en Salud pública.<br />
            Maestra en Administración de Hospitales y Salud Publica.<br />
            Educador en diabetes, master en insulinoterapia</>,
        description: 'Desde el año 2015 trabajo como médico especialista del Servicio de Consulta Externa y Medicina Preventiva de Medica Kolob. Mi trayectoria profesional ha sido marcada por el interés por la prevención y tratamiento de las complicaciones agudas y crónicas de la diabetes y en concreto, por la nefropatía, enfermedad e insuficiencia renal secundarias a diabetes. Fui especializándome en el ámbito de la diabetes mellitus inicialmente desde el punto de vista de la salud pública y la investigación, posteriormente desde el campo de la atención primaria, la prevención y actualmente desde el punto de vista clínico. Trabajo en una línea de colaboración con un equipo multidisciplinario sobre cada una de la fases de la historia natural de la enfermedad de la diabetes mellitus, aprendiendo siempre de la mano de referentes mundiales. Sabemos que atender los problemas de salud con excelencia, rigor y profesionalidad tiene un impacto en la esperanza y calidad de vida de cada persona que vive con diabetes. Este es el impulso que me mueve cada día.',
        imgPerfil: img1,
    },
    {
        nombre: 'Sinuhé Neftalí García Roldán',
        carrera: <>Médico Cirujano<br />Miembro activo de ANMGyF</>,
        especialidades: <>Educador en diabetes<br/>
        Gerontología.<br/>
        Advanced Life Support in Obstetrics (ALSO)</>,
        description: 'Desde el año 2015 trabajo como médico especialista del Servicio de  Consulta Externa y Geriatría Medica Kolob. Mi vida profesional ha sido trazada desde el punto de vista clínico por el interés de la atención integral del adulto mayor en la salud y enfermedad considerando aspectos clínicos terapéuticos, preventivos, paliativos y de rehabilitación precoz. Con énfasis en el paciente geriátrico y/o gerontológico que vive con diabetes mellitus ya que esta es una enfermedad estrechamente ligada al envejecimiento y su prevalencia se incrementa marcadamente con la edad. En Medica Kolob mi trabajo consiste especialmente en la atención integral del adulto mayor con o sin diabetes mellitus favoreciendo siempre el mantenimiento y la recuperación funcional para conseguir el máximo nivel de autonomía e independencia, de tal manera que se facilite su reinserción a una vida autosuficiente en el núcleo familiar y el entorno habitual. En cuanto a la atención del adulto mayor que vive con diabetes en Medica Kolob mi trabajo es realizar planes terapéuticos en base a una valoración integral teniendo en cuenta comorbilidades, capacidad  funcional y cognitiva, los trastornos afectivos y el soporte social, que en conjunto permiten estimar la expectativa de vida total y activa del paciente. Inicialmente me practica medica fue siempre clínica posteriormente desde un punto de vista de salud pública y de investigación. Actualmente soy gestor de calidad y médico adscrito al CSRC San Francisco Xonacatlán del Instituto de Salud del estado de México, así mismo soy médico responsable de servicio del turno vespertino. Trabajo en una línea de colaboración con un equipo multidisciplinario, somos un gran equipo, siempre damos lo mejor de nosotros a cada paciente.',
        imgPerfil: img2,
    },
    {
        nombre: 'Ana Jiménez Roldán ',
        carrera: <>Licenciada en Enfermería y Obstetricia</>,
        especialidades: <>Reingeniería y Administración de los Servicios de Enfermería.<br/>
        Administración de Competencias y Habilidades Laborales para el personal del Área  de Salud.</>,
        description: 'Soy licenciada en Licenciada en Enfermería y Obstetricia por la UNAM. He desempeñado mi profesión en  diferentes instituciones como INPer, Instituto Nacional de Nutrición, ISSSTE,  donde Descubrí mi vocación en el ámbito de la atención al paciente diabético. Además de la docencia en la ESEO del IPN. Desde el 2016 me encuentro al frente del Servicio de Clínica de Diabetes  de Medica Kolob. Los retos me estimulan, personal y profesionalmente.',
        imgPerfil: img3,
    },
    {
        nombre: 'José Juan Garcia Caballero',
        carrera: <>Psicólogo</>,
        especialidades: <>Psicología Clínica<br/>
        Prospecto a Maestro en Administración de Servicios de Salud (Actual)</>,
        description: 'Soy licenciado en Licenciada en Psicología por el CUI. En 2022 me incorporo a la familia de  Medica Kolob al Servicio Salud mental.  Medica Kolob  cuenta con un equipo multidisciplinario que hacen un gran trabajo colaborativo siempre buscando el bienestar del paciente, comprometidos en ofrecer medicina de excelencia.',
        imgPerfil: img4,
    },
    {
        nombre: 'Eduardo Gutiérrez Alcántara',
        carrera: <>Lic en Educación Física</>,
        especialidades: <>Entrenador  SICED II° Nivel<br/>
        Entrenador de personas con discapacidad <br/></>,
        description: 'Me he desempeñado a lo largo de 16 años como maestro de educación física en Preescolar, Primaría, Secundaria y Universidad en diferentes institucones, también me despeñado como entrenador Deportivo en diferentes Áreas como Natación, Basquetbol, Fútbol, Atletismo y Área de Psicomoticidad. Actualmenete soy docente de educación fisica en el Centro de Atencion Multiple No. 72 Profra. “Magarita Nieto hererra” donde trabajo con  personas con diferentes y multiples discapacidades. Mi funcion en Medica Kolob crear sensibilidad de la importancia del ejercicio en la s personas que viven con diabetes, ademas de orientar e infoormar para que las persona conozcan y comprender los efectos de la práctica habitual de actividad física sobre la salud y la calidad de vida, así como evaluar el estado de las capacidades físicas y habilidades motrices de las personas que viven con diabetes, planificando actividades que satisfagan sus gustos y necesidades.',
        imgPerfil: img5,
    },
    {
        nombre: 'Kevin Armando Sánchez Reyes',
        carrera: <>Lic. en Entrenamiento Deportivo y Cultura Física</>,
        especialidades: <>Entrenador deportivo<br/>
        Asesor de gimnacio<br/>
        Instructor de baile</>,
        description: 'A lo largo de ser un entrenador deportivo, he trabajado con personas con discapacidad, adultos y adultos mayores realizando planes de entrenamiento y enfocando en la corrección de los ejercicios.  Mi misión en Medica Kolob promover estilos de vida saludable a través del ejercicio y la practica del  deporte, mejorando así en el paciente que vive con diabetes su estado nutricional, su riesgo cardiovascular, control glicémico, salud mental y la fortaleza de sus huesos y músculos con el fin de llegar a una vejez lo más exitosa posible.',
        imgPerfil: img6,
    },
    {
        nombre: 'Sandra Peña Trujiullo',
        carrera: <>Cirujano Dentista</>,
        especialidades: <>Cirugía maxilofacial y oral<br/>
        Endodoncia<br/>
        Ortodoncia<br/>
        Odontoxesis</>,
        description: 'Mi trayectoria profesional ha sido marcada desde el punto de vista clínico por la prevención, diagnóstico y tratamiento de enfermedades bucodentales. La diabetes mellitus contribuye a una importante pérdida de la funcionalidad oral y a un fuerte decremento en la calidad de vida de estos pacientes. Por tal razón en Medica Kolob mi labor consiste prevención, promoción de la salud y profilaxis de las personas que viven con diabetes, así como en el tratamiento quirúrgico y farmacológico de las complicaciones orales. Actualmente soy jefa del servicio de estomatología del CSRC San Francisco Xonacatlán del Instituto de Salud del estado de México.',
        imgPerfil: img7,
    },{
        nombre: 'Andrea Monserrat Frasco Villegas',
        carrera: <>Licenciada en Nutrición</>,
        especialidades: <>Nutrición en paciente renal<br/>
        Maestría en nutrición clínica</>,
        description: 'Soy Licenciada en Nutrición por el CUI. A partir del 2022 me incorporo a equipo de trabajo de Medica Kolob al Servicio Nutrición Clínica. En Medica Kolob  trabajo en una línea de colaboración con un equipo multidisciplinario, siempre damos lo mejor de nosotros a cada paciente, otorgando servicios médicos de vanguardia y atención de calidad.',
        imgPerfil: img8,
    },
];

export default SobreNosotros;