import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';

import './body.css';
import { Button, Typography, ImageList, ImageListItem, Box, Grid } from "@mui/material";
import { Container } from "@mui/system";

const itemData = [
    // {
    //     img: 'https://empresas.blogthinkbig.com/wp-content/uploads/2020/09/10..9.El-papel-de-la-tecnolog%C2%B0a-en-la-cobertura-de-Salud.jpg',
    //     title: 'Consulta Digital',
    // },
    {
        img: 'https://blogthinkbig.com/wp-content/uploads/sites/4/2018/12/tecnologia-salud.jpg?fit=1911%2C1081',
        title: 'Consulta Laptop',
    },
    {
        img: 'https://www.conexiones365.com/img/blog/8-tendencias-tecnologia-salud--29.07.2020%208%20tecnologias%20de%20la%20salud.jpg',
        title: 'Consulta Celular',
    },
    {
        img: 'https://cdn.forbes.com.mx/2014/08/medicapanamericana.com_.jpg',
        title: 'Kitchen',
    },
    {
        img: 'https://amqueretaro.com/wp-content/uploads/2022/10/Salud-preventiva-y-uso-de-nuevas-tecnologias-urgencia-en-LATAM-1200x900.jpg',
        title: 'Blinds',
    },
    {
        img: 'https://www.galileo.edu/trends-innovation/wp-content/uploads/2020/02/IoT-avances2020-F-33.jpg',
        title: 'Laptop',
    },
];

function goDown() {
    document.getElementById("info").scrollIntoView({ behavior: 'smooth' }); // JUMP TO DIV "DOWN".
}

const heading = () => {
    AOS.init();
    return (
        <div>
            <div className="div">
                <Grid container spacing={0}>
                    <Grid md={6} xs={0}></Grid>
                    <Grid md={6} xs={12}>
                        <h1>Vivir con diabetes no es fácil.</h1>
                            <h2>Podemos ayudarte.</h2>
                        <Typography sx={{ paddingBottom: 5 }}>En Médica Kolob tu salud es primero.</Typography>
                        <Button size="large" onClick={goDown}
                            sx={{ padding: 2, borderColor: "black", border: 1.6, borderRadius: 3 }}>
                            Conoce más
                        </Button>
                    </Grid>
                </Grid>
            </div>
            <Container sx={{ paddingTop: 5, paddingBottom: 5}} id='info'>
                <Grid container>
                    <Grid md={6} xs={12} className='topic'>
                        <Box sx={{ paddingRight: { md: 10 }, paddingBottom: { xs: 4 } }} >
                            <Typography variant={'h3'} sx={{paddingBottom:5}}>
                                <strong>K2.0+<br />Kolob Liquido.</strong>
                            </Typography>
                            <Typography variant="body1" sx={{ textAlign: 'justify' }}>
                                La comunidad latina tiene 50% más probabilidades de desarrollar diabetes a comparación de los blancos no hispanos.<br />
                                A lo largo de su vida, los adultos estadounidenses tienen, en general, 40% de probabilidades de desarrollar diabetes tipo 2. Sin embargo, si eres latino, tu probabilidad es mayor al 50% y es probable que la desarrolles antes.<br />
                                Las complicaciones de la diabetes tienen un impacto en la comunidad latina. Esta población tiene tasas más altas de insuficiencia renal por diabetes así como pérdida de la visión relacionada con diabetes.
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid md={6} xs={12}>
                        <Box sx={{ paddingLeft: { xs: 2 }, paddingRight: { xs: 2 } }}>
                            <ImageList variant="masonry" cols={2} gap={8} sx={{ margin: 0, overflow:'hidden'}}>
                                {itemData.map((item, index) => (
                                    <ImageListItem key={item.img}>
                                        <img data-aos={index % 2 === 0 ? 'zoom-out-left' : 'zoom-out-right'}
                                            data-aos-offset="100"
                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            alt={item.title}
                                            loading="lazy"
                                            style={{ objectFit: 'cover' }}
                                        />
                                    </ImageListItem>
                                ))}
                            </ImageList>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
}

export default heading;